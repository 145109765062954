import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { enableMocking } from '@/shared/mocks/enable-mocks';

import { AppQueryClientProvider } from './config/app-query-client-provider';
import { RouterContextProvider } from './router-provider';

import './main.css';

import './i18n';

if (import.meta.env.VITE_ENABLE_SENTRY === 'true') {
  import('./config/sentry');
}
const root = document.querySelector('#root');

if (root) {
  enableMocking().then(() => {
    createRoot(root).render(
      <StrictMode>
        <AppQueryClientProvider>
          <RouterContextProvider />
        </AppQueryClientProvider>
      </StrictMode>,
    );
  });
}
