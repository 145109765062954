import { routeTree } from '@/app/routeTree.gen';
import type { QueryClient } from '@tanstack/react-query';
import { createRouter } from '@tanstack/react-router';

export const router = createRouter({
  routeTree,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: { queryClient: undefined as any as QueryClient },
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
