import { captureException } from '@sentry/react';

import type { ApiOptions } from '@/shared/api/typings/api-options';

import type { AppEntityApi } from '../api/app-entity-api';
import { Entity } from '../typings/entity';

export class EntityRepository {
  private api: AppEntityApi;

  constructor(data: { api: AppEntityApi }) {
    this.api = data.api;
  }

  public async getEntities(options?: ApiOptions) {
    const response = await this.api.getEntities(options);

    const entities = response.entities ?? [];
    return entities
      .map((dto) => {
        try {
          return Entity.fromDto(dto);
        } catch (error) {
          // todo: Добавить общую функцию для отправки ошибки, когда одна из сущностей
          // не смогла распарситься
          captureException(error);
          return;
        }
      })
      .filter((entity) => entity != undefined);
  }

  public async getEntityById(entityId: string) {
    const response = await this.api.getEntityById(entityId);
    const dto = response.entity;

    if (!dto) {
      throw new Error('Entity not found');
    }

    return Entity.fromDto(dto);
  }
}
