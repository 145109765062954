import { EntityApi } from '@/shared/api/generated/fetch-client/index';

export class AppEntityApi {
  private api: EntityApi;

  constructor(data: { api: EntityApi }) {
    this.api = data.api;
  }

  async getEntityById(entityId: string, initOverrides?: RequestInit) {
    return this.api.getEntityById({ entityId }, initOverrides);
  }

  async getEntities(initOverrides?: RequestInit) {
    return this.api.getEntities(initOverrides);
  }
}
