import { createFileRoute } from '@tanstack/react-router';

import { MainPage } from '@/pages/main-page/main-page';

import { AppEntityApi } from '@/entities/example-entity/api/app-entity-api';
import { EntityRepository } from '@/entities/example-entity/repository/entity-repository';

import { Configuration, EntityApi } from '@/shared/api/generated/fetch-client';

export const Route = createFileRoute('/')({
  context: () => ({
    entityRepository: new EntityRepository({
      api: new AppEntityApi({
        api: new EntityApi(
          new Configuration({
            basePath: '',
          }),
        ),
      }),
    }),
  }),
  component: MainPage,
});
