import { useMemo } from 'react';

import { router } from '@/app/config/router-config';
import { useQueryClient } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';

export function RouterContextProvider() {
  const queryClient = useQueryClient();

  const context = useMemo(() => ({ queryClient }), [queryClient]);

  return <RouterProvider router={router} context={context} />;
}
