import type { EntityDto } from '@/shared/api/generated/fetch-client';

export class Entity {
  public id: string;
  public name?: string | null;

  constructor(data: { name: string | undefined | null; id: string }) {
    this.name = data.name;
    this.id = data.id;
  }

  static fromDto(dto: EntityDto) {
    if (!dto.id) {
      throw new Error('Entity id not found');
    }

    return new Entity({
      id: dto.id,
      name: dto.name,
    });
  }
}
