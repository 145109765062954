/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouteContext } from '@tanstack/react-router';

export function useEntityRepository() {
  const repository = useRouteContext({
    strict: false,
    select: (context: any) => context.entityRepository,
  });

  if (!repository) {
    throw new Error('EntityRepository must be provided in context');
  }

  return repository;
}
