import { PropsWithChildren } from 'react';

type Props = {
  name: string;
};

export function EntityCard({ name, children }: PropsWithChildren<Props>) {
  return (
    <div>
      <h1>{name}</h1>

      <div>{children}</div>
    </div>
  );
}
