import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import type { EntityRepository } from '@/entities/example-entity/repository/entity-repository';
import { useEntityRepository } from '@/entities/example-entity/repository/use-entity-repository';

export function useEntities() {
  const repository = useEntityRepository();

  const { data } = useSuspenseQuery(useEntities.options(repository));

  return { entities: data };
}

useEntities.keys = () => ['entities'] as const;

useEntities.options = (repository: EntityRepository) =>
  queryOptions({
    queryKey: useEntities.keys(),
    queryFn: ({ signal }) => repository.getEntities({ signal }),
  });
