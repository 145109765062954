/* tslint:disable */
/* eslint-disable */
/**
 * Example API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EntityDto } from './entity-dto';
import {
    EntityDtoFromJSON,
    EntityDtoFromJSONTyped,
    EntityDtoToJSON,
    EntityDtoToJSONTyped,
} from './entity-dto';

/**
 * 
 * @export
 * @interface GetEntities200Response
 */
export interface GetEntities200Response {
    /**
     * 
     * @type {Array<EntityDto>}
     * @memberof GetEntities200Response
     */
    entities?: Array<EntityDto>;
}

/**
 * Check if a given object implements the GetEntities200Response interface.
 */
export function instanceOfGetEntities200Response(value: object): value is GetEntities200Response {
    return true;
}

export function GetEntities200ResponseFromJSON(json: any): GetEntities200Response {
    return GetEntities200ResponseFromJSONTyped(json, false);
}

export function GetEntities200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEntities200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'entities': json['entities'] == null ? undefined : ((json['entities'] as Array<any>).map(EntityDtoFromJSON)),
    };
}

  export function GetEntities200ResponseToJSON(json: any): GetEntities200Response {
      return GetEntities200ResponseToJSONTyped(json, false);
  }

  export function GetEntities200ResponseToJSONTyped(value?: GetEntities200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'entities': value['entities'] == null ? undefined : ((value['entities'] as Array<any>).map(EntityDtoToJSON)),
    };
}

