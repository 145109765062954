import { useTranslation } from 'react-i18next';

import { EntityCard } from '@/entities/example-entity/components/entity-card/entity-card';
import { useEntities } from '@/entities/example-entity/hooks/use-entities';

import { Button } from '@/shared/shadcn/components/ui/button';

export function MainPage() {
  const { t } = useTranslation();

  const { entities } = useEntities();

  return (
    <>
      <Button
        onClick={() => {
          throw new Error('test');
        }}
      >
        throw
      </Button>
      <div>{t('hello')}</div>

      {entities.map((entity) => (
        <EntityCard key={entity.id} name={entity.name ?? ''} />
      ))}
    </>
  );
}
