/* tslint:disable */
/* eslint-disable */
/**
 * Example API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetEntities200Response,
  GetEntityById200Response,
  GetEntityById404Response,
} from '../models/index';
import {
    GetEntities200ResponseFromJSON,
    GetEntities200ResponseToJSON,
    GetEntityById200ResponseFromJSON,
    GetEntityById200ResponseToJSON,
    GetEntityById404ResponseFromJSON,
    GetEntityById404ResponseToJSON,
} from '../models/index';

export interface GetEntityByIdRequest {
    entityId: string;
}

/**
 * 
 */
export class EntityApi extends runtime.BaseAPI {

    /**
     */
    async getEntitiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEntities200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/entities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEntities200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getEntities(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEntities200Response> {
        const response = await this.getEntitiesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getEntityByIdRaw(requestParameters: GetEntityByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEntityById200Response>> {
        if (requestParameters['entityId'] == null) {
            throw new runtime.RequiredError(
                'entityId',
                'Required parameter "entityId" was null or undefined when calling getEntityById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/entities/{entityId}`.replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters['entityId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEntityById200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getEntityById(requestParameters: GetEntityByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEntityById200Response> {
        const response = await this.getEntityByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
